<template>
  <div class="container">
    <navbar title="商品详情"></navbar>
    <c-sticky v-if="orders.length" :offset-top="$px2vwMin(92)">
      <vertical-notice>
        <van-swipe-item v-for="(item, index) in orders" :key="index"><span class="notice-f">{{item.factory_name}}</span><span class="notice-d"> 下单了 </span><span class="notice-t">{{item.toy_name}}</span></van-swipe-item>
      </vertical-notice>
    </c-sticky>
    <van-tabs v-model="active" scrollspy sticky
      title-active-color="#4F4F4F"
      title-inactive-color="#999999"
      :offset-top="$px2vwMin(orders.length ? 160 : 92)"
      >
      <van-tab v-for="(item, index) in tabsItems" :title="item" :key="index">
        <div v-if="item == '商品'">
          <div
            class="image-swipe"
            v-if="detail.pictures && detail.pictures.length > 0"
          >
            <swiper
              ref="mySwiper"
              :options="swiperOptions"
            >
              <swiper-slide
                v-for="(img, i) in detail.pictures"
                :key="i"
              >
                <img :src="getSrc(img)" />
              </swiper-slide>
              <div
                class="swiper-pagination"
                slot="pagination"
              ></div>
            </swiper>
          </div>
          <div class="top-container">
            <div class="top-left">
              <div class="toy-name">{{detail.name}}</div>
              <div class="toy-number"><span>{{detail.article_number}}</span></div>
            </div>
            <div
              class="top-right"
              @click="collect"
            >
              <template v-if="detail.collect>0">
                <img
                  class="collect-icon"
                  :src="require('@/assets/images/icon/cancel_collect.png')"
                />
                <div class="collect-text">取消收藏</div>
              </template>
              <template v-else>
                <img
                  class="collect-icon"
                  :src="require('@/assets/images/icon/collect.png')"
                />
                <div class="collect-text">收藏</div>
              </template>
            </div>
          </div>
        </div>
        <div v-if="item == '报价'" class="section">
          <div class="block-title">
            <div class="title-left-line"></div>
            <div class="title-content">报价商家</div>
            <div class="title-right">{{detail.supplier ? detail.supplier.length : 0}}个报价</div>
          </div>
          <div class="list">
            <van-list
              v-model="loading"
              :finished="finished"
              :error="error"
              finished-text=""
              @load="onLoad"
            >
              <div
                :class="{'item': true, 'item-pitch-on': selectItem.id == item.id}"
                @click="select(item)"
                v-for="(item, i) in detail.supplier"
                :key="i"
              >
                <div class="select-tag">
                  <van-icon
                    name="success"
                    :size="$px2vw(24)"
                  />
                </div>
                <div class="item-name">{{item.name}}</div>
                <div class="item-bottom">
                  <div class="item-price">
                    <div class="price-unit"></div>
                    <div class="price-num"><span class="money-uni">￥</span>{{item.tax_excluding_price}} <span class="quote_unit">/{{detail.quote_unit}}</span></div>
                    <!-- <div class="include-tax">
                      <div class="include-tax-tag">含税</div>
                      <div class="include-tax-price">￥{{item.tax_inclusive_price}}</div>
                    </div> -->
                  </div>
                  <div class="item-stock">库存{{item.stock_amount}}{{detail.quote_unit}}</div>
                </div>
              </div>
            </van-list>
          </div>
        </div>
        <div v-if="item == '购买须知'" class="section notice-section">
          <div class="block-title">
            <div class="title-left-line"></div>
            <div class="title-content">购买须知</div>
          </div>
          <div v-html="detail.purchase_note" class="notice-detail"></div>
        </div>
        <div v-if="item == '详情'" class="section notice-section">
          <div class="block-title">
            <div class="title-left-line"></div>
            <div class="title-content">商品详情</div>
          </div>
          <div v-html="detail.detail" class="detail"></div>
        </div>
      </van-tab>
    </van-tabs>
    <div class="bottom-operate-placeholder"></div>
    <div class="bottom-operate">
      <div class="button-tips" v-if="detail.remaining !== null && detail.remaining !== 'null' && detail.remaining != undefined && detail.remaining != 'undefined'">
        <van-icon class="warning-o-cus" name="warning-o" color="#FC5D5D" :size="$px2vw(28)" />
        <div>当前可用额度{{detail.remaining}}元 <span v-if="stockMax > 0 && detail.remaining > 0 && canBuyNum > 0">，可以购买{{canBuyNum}}吨</span></div>
      </div>
      <div class="buttom-add">
        <div class="num-block">
          <div class="total-price-unit">￥<span class="total-price-num">{{totalPrice}}</span></div>
          <div class="num-unit">
            <van-stepper
              v-model="num"
              class="stepper-num"
              :max="stockMax"
              :button-size="$px2vw(48)"
              disable-input
            />
            <div class="quote_unit">/{{detail.quote_unit}}</div>
          </div>
        </div>
        <div class="btn-group">
          <div class="contact_business" @click="toChat">
            <img class="contact_icon" :src="require('@/assets/images/icon/contact_business.png')">
            <div>咨询商家</div>
          </div>
          
          <div v-if="!jobStatus" class="button button-disabled">非营业时间 不可下单</div>
          <div v-else class="button" @click="placeOrder">立即下单</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toyDetail, toyCollect, toyCollectDelete, toyOrderTmp } from "@/api/toy.js";
import Navbar from "@/components/navbar.vue";
import VerticalNotice from '@/components/vertical-notice'
import CSticky from "@/components/c-sticky.vue";

export default {
  name: "toyDetail",
  components: {
    Navbar,
    VerticalNotice,
    CSticky
  },
  inject: ['_app'],
  data() {
    return {
      id: '',
      // current: 0,
      loading: false,
      finished: true,
      error: false,
      list: [],
      selectItem: {},
      num: 1,
      detail: {},
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction'
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
      },
      tabsItems: ['商品', '报价'],
      active: 0,
      jobStatus: true,
      orders: []
    };
  },
  mounted() {
    this.id = this.$route.params?.id;
    this.getItem(true);
    this.getOrders();
  },
  methods: {
    getItem(init = false) {
      toyDetail({ id: this.id }).then(res => {
        if (res.code == 0) {
          this.tabsItems = ['商品', '报价']
          this.detail = res.data;
          this.selectItem = this.detail.supplier[0];
          if (this.detail.purchase_note) this.tabsItems.push('购买须知');
          if (this.detail.detail) this.tabsItems.push('详情');
          this.setJobStatus()
        } else {
          this.$toast({
            message: res.msg, onClose: () => {
              if (init) this.$back();
            }
          })
        }
      })
    },
    getOrders() {
      toyOrderTmp()
        .then(res => {
          if (res.code == 0 && res.data) {
            this.orders = res.data
          }
        })
    },
    setJobStatus() {
      if (this.selectItem) {
        if (!this.selectItem.enable_business_hours || !this.selectItem.start_time || !this.selectItem.end_time) {
          this.jobStatus = true
          return;
        }
        let date = new Date();
        var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
        var m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes() + ':';
        var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        var time = h + m + s;
        if (this.selectItem.start_time > time || this.selectItem.end_time < time) {
          this.jobStatus = false
          return;
        }
      }
    },
    // onChange(index) {
    //   this.current = index;
    // },
    //收藏/取消收藏
    collect() {
      if (this.detail.collect == 0) {
        toyCollect({ id: this.id }).then(res => {
          if (res.code == 0) {
            this.getItem();
            this._app.reload('collect'); //刷新收藏列表
          } else {
            this.$toast(res.msg);
          }
        })
      } else {
        toyCollectDelete({ id: [this.detail.collect] }).then(res => {
          if (res.code == 0) {
            this.getItem();
            this._app.reload('collect');  //刷新收藏列表
          } else {
            this.$toast(res.msg);
          }
        })
      }
    },
    onLoad() {

    },
    select(item) {
      if (item.id == this.selectItem.id) return false;
      this.selectItem = item;
      this.num = 1;
      this.setJobStatus();
    },
    placeOrder() {
      if (this.num == 0 || JSON.stringify(this.selectItem) == '{}') {
        this.$toast('请选择报价商家');
        return false;
      }
      if (this.selectItem.stock_amount < 1) {
        this.$toast('库存不足');
        return false;
      }
      this.$router.push({ name: 'orderAdd', query: { supplier_id: this.selectItem.id, toy_id: this.id, num: this.num } })
    },
    getSrc(img) {
      return img + '?t=1662028314053' 
    },
    toChat() {
      if (JSON.stringify(this.selectItem) == '{}') {
        this.$toast('请选择报价商家');
        return false;
      }
      if (this.selectItem.chat_id) {
        this.$router.push(`/chat/detail?cid=${this.selectItem.chat_id}&&cname=${this.selectItem.nickname}`)
      } else {
        this.$toast('暂时不能与该商家联系！');
      }
    }
  },
  computed: {
    totalPrice() {
      if (this.num == 0 || JSON.stringify(this.selectItem) == '{}') return 0;
      return this.$moneyFormat(this.num * this.selectItem.tax_excluding_price);
    },
    stockMax() {
      if (JSON.stringify(this.selectItem) == '{}') return 1;
      return this.selectItem.stock_amount;
    },
    canBuyNum() {
      return Math.min(Math.floor(this.detail.remaining / this.selectItem.tax_excluding_price), this.stockMax);
    },
  }
};
</script>
<style lang="scss" scoped>
.container {
  .image-swipe {
    width: 100%;
    height: 563px;
    background: #ffffff;
    img {
      height: 563px;
      width: 100%;
      object-fit: contain;
    }
    :deep(.swiper-pagination.swiper-pagination-fraction){
      position: absolute;
      display: flex;
      width: auto;
      left: auto;
      right: 30px;
      bottom: 20px;
      padding: 0 16px;
      font-size: 24px;
      line-height: 48px;
      border-radius: 41px;
      background: rgba(240, 240, 240, 0.9);
      height: 48px;

      font-weight: 400;
      color: #999999; 
    }
  }
  .top-container {
    // height: 120px;
    width: 100%;
    padding: 17px 30px;
    box-sizing: border-box;
    background: url(~@/assets/images/toy/bg.png);
    background-size: 100% 100%;
    display: flex;
    .top-left {
      flex: 1;
      overflow: hidden;
      .toy-name {
        font-size: 34px;
        font-weight: bold;
        color: #ffffff;
        // @include not-wrap;
      }
      .toy-number {
        margin-top: 6px;
        span {
          border-radius: 4px;
          font-size: 24px;
          font-weight: 400;
          color: #ffe8a8;
          border: 1px solid #ffe8a8;
          padding: 0 14px;
          max-width: 90%;
          display: inline-block;
          @include not-wrap;
        }
      }
    }
    .top-right {
      flex-shrink: 0;
      text-align: center;
      min-width: 96px;
      .collect-icon {
        width: 50px;
        height: 48px;
      }
      .collect-text {
        font-size: 24px;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .block-title {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    position: relative;
    background: #ffffff;
    border-bottom: 1px solid #F6F6F6;
    .title-left-line {
      width: 8px;
      height: 36px;
      background: #1aa3ff;
      position: absolute;
      left: 0;
    }
    .title-content {
      font-size: 34px;
      font-weight: bold;
      color: #4f4f4f;
    }
    .title-right {
      font-size: 28px;
      font-weight: 400;
      color: #ff8282;
    }
  }
  .list {
    padding: 0 20px 29px;
  }
  .item {
    margin: 20px 0;
    margin-bottom: 0;
    background: #F5F7F8;
    border-radius: 10px;
    padding: 24px 30px;
    box-sizing: border-box;
    border: 1px solid #ffffff;
    overflow: hidden;
    .select-tag {
      display: none;
    }
    .item-name {
      font-size: 30px;
      font-weight: bold;
      color: #4F4F4F;
      @include not-wrap;
    }
    .item-bottom {
      margin-top: 9px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item-price {
        color: #f33636;
        display: flex;
        align-items: flex-end;
        .price-unit {
          line-height: 28px;
          font-size: 28px;
          font-weight: bold;
        }
        .price-num {
          // line-height: 40px;
          font-size: 40px;
          font-weight: bold;
          .money-uni{
            font-size: 24px;
          }
          .quote_unit{
            font-size: 26px;
            color: #666666;
          }
        }
        .include-tax {
          display: flex;
          height: 36px;
          line-height: 36px;
          background: #ffe8e8;
          border-radius: 6px;
          border: 1px solid #f33636;
          margin-left: 27px;
          .include-tax-tag {
            background: url(~@/assets/images/toy/price_icon.png);
            width: 68px;
            height: 36px;
            background-size: 100% 100%;
            font-size: 24px;
            line-height: 24px;
            color: #ffffff;
            display: flex;
            align-items: center;
            padding-left: 8px;
            box-sizing: border-box;
          }
          .include-tax-price {
            display: flex;
            align-items: center;
            font-size: 26px;
            line-height: 26px;
            font-weight: 400;
            color: #f33636;
            padding-left: 4px;
            padding-right: 10px;
          }
        }
      }
      .item-stock {
        font-size: 26px;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .item-pitch-on {
    background: #ffecec;
    border-radius: 10px;
    border: 1px solid #f33636;
    position: relative;
    .select-tag {
      position: absolute;
      right: 0;
      top: 0;
      width: 48px;
      height: 32px;
      border-radius: 0 0 0 10px;
      background-color: #f33636;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .section{
    background: #fff;

    .notice-detail{
      padding: 18px 30px;
    }
    .detail{
      width: 100%;
      padding: 30px 30px;
      box-sizing: border-box;

      :deep(img){
        display: block;
        max-width: 100%;
      }
    }
  }
  .notice-section{
    margin-top: 10px
  }
  .bottom-operate-placeholder {
    @include safe-area-inset-bottom-height(260px);
    // background: #fff;
  }
  .bottom-operate {
    width: 100%;
    background: #ffffff;
    position: fixed;
    bottom: 0;
    left: 0;

    .button-tips{
      padding: 20px;
      display: flex;
      align-items: center;
      background: #FFF8F8;
      font-size: 24px;
      color: #FC5D5D;

      .warning-o-cus{
        margin-right: 10px;
      }
    }
    .buttom-add{
      padding-top: 25px;
      padding-left: 30px;
      padding-right: 30px;
      @include safe-area-inset-bottom(20px);
    }
    .num-block{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .total-price-unit{
        color: #F33636;
        font-size: 28px;

        .total-price-num{
          font-size: 48px;
          font-weight: bold;
        }
      }
      .num-unit{
        display: flex;
        align-items: center;
        .stepper-num {
          :deep(.van-stepper__input) {
            background-color: #ffffff;
            margin: 0;
          }
          :deep(.van-stepper__minus) {
            background-color: #EBF7FF;
          }
          :deep(.van-stepper__plus) {
            background-color: #EBF7FF;
          }
          :deep(.van-stepper__minus, .van-stepper__plus){
            color: #666666;
          }
          :deep(.van-stepper__minus--disabled, .van-stepper__plus--disabled){
            color: #BBBBBB !important;
          }
        }
        .quote_unit{
          margin-left: 10px;
          font-size: 26px;
          font-weight: 500;
          color: #707070;
        }
        // .num-unit {
        //   font-size: 26px;
        //   font-weight: 500;
        //   color: #707070;
        //   margin-left: 10px;
        //   flex-shrink: 0;
        // }
      }
    }
    .btn-group{
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .contact_business{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 328px;
      height: 70px;
      border-radius: 41px;
      border: 1px solid #1AA3FF;
      font-size: 28px;
      color: #1AA3FF;
      .contact_icon{
        margin-right: 7px;
        width: 34px;
        height: 34px;
      }
    }
    .button {
      width: 328px;
      height: 70px;
      background: #1aa3ff;
      border-radius: 41px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 28px;
      font-weight: 400;
      color: #ffffff;
    }
  }
  :deep(.van-tab){
    font-size: 28px;
  }
  :deep(.van-tabs__line){
    background-color: #1AA3FF;
    border-radius: 3px 3px 3px 3px;
  }
  .button-disabled{
    color: #BEB3B3 !important;
    background: #F1F1F1 !important;
  }
  .notice-f{
    color: #666666;
  }
  .notice-d{
    color: #999999;
  }
  .notice-t{
    color: #0090FF;
  }
}
</style>
