<template>
  <van-notice-bar style="height: 34px;line-height:34px" :left-icon="require('../assets/images/icon/notice.png')" :scrollable="false" background="#ecf9ff">
    <van-swipe
      vertical
      class="notice-swipe"
      style="height: 34px;line-height: 35px"
      :autoplay="3000"
      :show-indicators="false"
    >
      <slot></slot>
    </van-swipe>
  </van-notice-bar>
</template>

<script>
export default {
  props: {
    background: {
      type: String,
      default: '#CBEEFF'
    }
  }
}
</script>

<style lang="scss" scoped>
// .van-notice-bar{
//   line-height: 68px;
// }
// .notice-swipe {
//   height: 68px;
//   line-height: 68px;
// }
</style>